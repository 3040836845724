<template>
    <div :key="clipboardText" class="clipboard_enable --no-hover">
        <p class="clipboard_enable__cod">{{ clipboardText }}</p>
        <span class="clipboard_enable__icone" v-clipboard="clipboardText" v-clipboard:success="clipboardSuccessHandler">
            <transition name="fade" mode="out-in">
                <IconCopy v-if="!clipboardSuccess"></IconCopy>
                <IconCheck :iconColor="'#5ec604'" v-else></IconCheck>
            </transition>
        </span>
    </div>
</template>

<script>
import IconCopy from "@/components/icones/IconCopy.vue";
import IconCheck from "@/components/icones/iconCheck.vue";

export default {
  props: ["clipboardText"],
  components: {
      IconCopy,
      IconCheck,
  },
  data() {
    return {
        clipboardSuccess: false,
    };
  },
  methods: {
    clipboardSuccessHandler() {
      this.clipboardSuccess = true;
      setTimeout(() => {
        this.clipboardSuccess = false;
      }, 1000);
    },
    copy(data) {
      data.select();
      document.execCommand("Copy");
    },
  },
};
</script>

<style lang="scss" scoped>
    .clipboard_enable {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px;
        transition: all 350ms ease-in-out;
        padding: 10px 0;
        &:hover {
            background-color: #e6e6e6;
        }
        &.--no-hover {
            background-color: #ffffff;
        }
        p {
            font-size: 16px;
            font-family: $font__main;
            color: #2c2c2c;
        }

        span {
            font-size: 16px;
            font-family: $font__main;
            color: #2c2c2c;
            // opacity: 0.5;
        }
        &__cod {
            color: #9f9f9f;
        }
        &__icone {
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
    }
</style>