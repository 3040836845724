<template>
  <div class="field">
    <label for="name"> {{ data.title }} </label>
    <input
      v-on:click="handleChange"
      :name="data.name"
      :type="data.type"
      :value="data.value"
      :readonly="data.readonly"
      required
    />
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleChange() {
      this.$emit("active:button", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  flex-direction: column;

  label {
    font-size: 16px;
    font-weight: bolder;
    font-family: $font__main;
    padding-bottom: 10px;
  }

  input {
    max-width: 361px;
    width: 100%;
    height: 49px;
    border: solid 2px;
    border-color: #e6e6e6;
    margin-bottom: 25px;
  }
}
</style>
