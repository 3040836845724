<template>
  <div class="configform">
    <div class="box">
      <form @submit.prevent="handleSave">
        <div class="field">
          <inputValidate :erro="accountNameErro">
            <label for="nome">Accountname</label>
            <input
              autocomplete="off"
              type="text"
              name="accountName"
              v-bind:class="$v.form.accountName.$invalid && $v.form.appkey.$dirty ? 'has-error' : ''"
              v-model.trim="form.accountName"
              @blur="$v.form.accountName.$touch()"
            />
          </inputValidate>
        </div>

        <div class="field">
          <inputValidate :erro="appkeyErro">
            <label for="e-mail">App key</label>
            <input
              autocomplete="off"
              type="text"
              name="appkey"
              v-bind:class="$v.form.appkey.$invalid && $v.form.appkey.$dirty ? 'has-error' : ''"
              v-model.trim="form.appkey"
              @blur="$v.form.appkey.$touch()"
            />
          </inputValidate>
        </div>

        <div class="field">
          <inputValidate :erro="apptokenErro">
            <label for="telefone">App Token</label>
            <input
              autocomplete="off"
              type="password"
              name="apptoken"
              v-bind:class="$v.form.apptoken.$invalid && $v.form.appkey.$dirty ? 'has-error' : ''"
              v-model.trim="form.apptoken"
              @blur="$v.form.apptoken.$touch()"
            />
          </inputValidate>
        </div>

        <div class="button-box">
          <button class="save" type="submit">FINALIZAR CONFIGURAÇÃO</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import * as vuelidate from "@/plugins/vuelidate/setup";
import { cadastrarVtexAccount } from "@/api/vtex";
import inputValidate from "@/components/formulario/InputValidate.vue";
export default {
  components: {
    inputValidate,
  },
  data() {
    return {
      form: {
        accountname: "",
        appkey: "",
        apptoken: "",
      },
    };
  },
  validations: {
    form: {
      accountName: {
        required,
      },
      appkey: {
        required, 
      },
      apptoken: {
        required,
      },
    },
  },
  computed: {
    accountNameErro: vuelidate.accountNameErro,
    appkeyErro: vuelidate.appkeyErro,
    apptokenErro: vuelidate.apptokenErro,
  },
  methods: {
    cadastrarVtexAccount,
    handleSave() {
      if (this.$v.form.$invalid) {
        this.$v.$touch();
        return;
      }

      const data = {
        accountname: this.form.accountName,
        vtexappkey: this.form.appkey,
        vtexapptoken: this.form.apptoken,
      };
      this.cadastrarVtexAccount(data);
    },
  },
};
</script>

<style lang="scss">
.configform {
  width: 100%;
  height: 100%;
  .box {
    form {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 25px;
      .field {
        width: 100%;
        label {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 10px;
          display: block;
          color: $gray;
        }

        input {
          width: 100%;
          height: 40px;
          border-radius: 6px;
        }
        .input-erro-container {
          .has-error {
            border-color: #f83505;
          }
          .input-erro-mensagem {
            color: #f83505;
            font-size: 12px;
            margin-top: 0;
            position: relative;
            bottom: inherit;
            top: -15px;
            font-weight: 500;
          }
        }
      }
    }

    .button-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .save {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 335px;
        width: 100%;
        height: 51px;
        border-radius: 5px;
        border: none;
        background-color: #73cb53;
        margin: 7.5px;
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        font-family: $font__main;
        text-transform: uppercase;
        &:focus {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}
</style>
