<template>
  <IntegrationBox>
    <button v-on:click="handleClose" class="close">X</button>
    <div class="box">

      <div class="mb-30" v-if="type === 'nuvemshop'">
        <img
          src="~@/assets/images/icones/logo-nuvemshop.svg"
          height="35"
          alt="Nuvemshop"
          
          />
        <br />
        <b class="mt-0">Nuvemshop</b>
      </div>

      <div class="mb-30" v-if="type === 'vtexlegacy'">
        <img
          src="~@/assets/images/icones/logo-vtex.svg"
          height="35"
          alt="VTEX"
          
          />
        <br />
        <b class="mt-0">VTEX</b>
      </div>

      <div class="mb-30" v-if="type === 'vtexio'">
        <img
          src="~@/assets/images/icones/logo-vtex.svg"
          height="35"
          alt="VTEX IO"
          
          />
        <br />
        <b class="mt-0">VTEX IO</b>
      </div>

      <div class="fields">
        <Fieldinput v-for="input in mountInputs" :key="input.title" :data="input" />
      </div>
    </div>
  </IntegrationBox>
</template>

<script>
import Fieldinput from "@/components/field-input/Fieldinput.vue";
import IntegrationBox from "@/components/integration-box/Integrationbox.vue";

export default {
  props: [
    "edit",
    "integrations",
  ],
  components: {
    IntegrationBox,
    Fieldinput,
  },
  data() {
    return {
      inputs: [],
      inputsValues: {},
      type: null,
      integration: null,
      inputVtexLegacy: [
        {
          title: "Accountname",
          type: "redonly",
          name: "accountname",
          value: "",
          readonly: true,
        },
        {
          title: "App Key",
          type: "text",
          name: "vtexappkey",
          value: "",
          readonly: true,
        },
        {
          title: "App Token",
          type: "password",
          name: "vtexapptoken",
          value: "",
          readonly: true,
        }
      ],
      inputNuvemshop: [
        {
          title: "ID Loja",
          type: "redonly",
          name: "storeid",
          value: "",
          readonly: true,
        },
        {
          title: "Nome da Loja",
          type: "redonly",
          name: "store_name",
          value: "",
          readonly: true,
        },
        {
          title: "URL da Loja",
          type: "redonly",
          name: "store_url",
          value: "",
          readonly: true,
        }
      ],
      inputVtexIo: [
        {
          title: "Accountname",
          type: "redonly",
          name: "accountname",
          value: "",
          readonly: true,
        },
      ],
    }
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      let integration = this.integrations.filter(obj => obj.id === this.edit);
      this.integration = (integration.length > 0) ? integration[0] : null;
      this.type = (this.integration != null) ? this.integration.type : null;
      // console.log('this.integration',  this.integration);
      switch (this.type) {
        case 'vtexlegacy':
          this.inputs = this.inputVtexLegacy;
          this.inputsValues = this.integration.vtex_legacy;
          break;
        case 'nuvemshop':
          this.inputs = this.inputNuvemshop;
          this.inputsValues = this.integration.nuvemshop;
          break;
        case 'vtexio':
          this.inputs = this.inputVtexIo;
          this.inputsValues = this.integration.vtex_io;
          break;
        default: break;
      }
    },
    getIndex(type) {
      return this.inputs.findIndex(input => input.name === type);
    },
    handleClose() {
      this.$emit("closeAddIntegration", false);
    },
  },
  computed: {
    mountInputs() {
      let inputs = this.inputs;
      if (!inputs || inputs.length === 0) return;

      // console.info('mountInputs inputs', inputs);
      // console.info('mountInputs inputsValues', this.inputsValues);

      inputs.forEach(input => {
        input.value = this.inputsValues[input.name];
      });

      return inputs;
    }
  },
};
</script>

<style lang="scss" scoped>
.close {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: none;
}
.integration {
  .box {
    flex: 1;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;

    form {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      button {
        max-width: 335px;
        width: 100%;
        height: 51px;
        border-radius: 5px;
        background-color: #41a7ff;
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        font-family: $font__main;
        border: none;
      }
    }

    .init-button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 335px;
      width: 100%;
      height: 51px;
      border-radius: 5px;
      background-color: #73cb53;

      a {
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        font-family: $font__main;
      }
    }

    .delete-button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 335px;
      width: 100%;
      height: 51px;
      border-radius: 5px;
      background-color: #ffffff;
      border: 1px solid;
      border-color: #000000;
      cursor: pointer;

      a {
        color: #ff0000;
        font-size: 16px;
        font-weight: bold;
        font-family: $font__main;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 700px) {
}
</style>
