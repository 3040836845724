<template>
  <div class="integration">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.integration {
  position: relative;
  display: flex;
  max-width: 585px;
  width: 100%;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  padding: 18px;
  margin-left: 33.5px;
  margin-top: 65px;
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: white;
    position: absolute;
    left: -8px;
    top: 52px;
    transform: rotateZ(45deg);
  }
}
</style>
