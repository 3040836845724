import client from "@/api";
import store from "@/store";
import { _rotaIntegrations } from "@/api/_caminhosApi";

export function listIntegrations() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("integrations/SET_INTEGRATIONS", null);
  client
    .get(`${_rotaIntegrations()}`)
    .then(resp => {
      if (resp.status === 200 && resp.data.integrations.length > 0) {
        store.commit("integrations/SET_INTEGRATIONS", resp.data.integrations);
      } else {
        store.commit("integrations/SET_INTEGRATIONS", []);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
