<template>
  <div class="integration" :class="profile && profile.company_data ? '' : 'mt-none'">
    <div class="box">
      <div
        class="title"
        :class="integrations !== null && integrations.length > 0 ? '--minor' : ''"
        v-if="integrations !== null && integrations.length > 0" >
        <p>Integrações ativas</p>
      </div>
      <div class="content">

        <div class="list list--cards" v-if="integrations !== null && integrations.length > 0">
          <div
            v-if="vtexLegacy"
            :key="vtexLegacy.id" 
            class="integration__card" 
            v-on:click="handleEdit(vtexLegacy.id)"
            >
            <img src="~@/assets/images/icones/logo-vtex.svg" alt="VTEX" />
            <p>VTEX <small>API</small></p>
          </div>

          <!-- <div
            v-if="vtexIo"
            :key="vtexIo.id" 
            class="integration__card" 
            v-on:click="handleEdit(vtexIo.id)"
            >
            <img src="~@/assets/images/icones/logo-vtex.svg" alt="VTEX" />
            <p>VTEX <small>IO</small></p>
          </div> -->

          <div
            v-if="nuvemshop"
            :key="nuvemshop.vtexappkey" 
            class="integration__card" 
            v-on:click="handleEdit(nuvemshop.id)"
            >
            <img src="~@/assets/images/icones/logo-nuvemshop.svg" alt="nuvemshop" />
            <p>Nuvemshop<br /><small>Loja {{nuvemshop.nuvemshop.storeid}}</small></p>
          </div>
        </div>

        <div v-else>
          <div class="title">
            <p>Integrações disponíveis</p>
          </div>

          <div class="content__cards">
            <div class="integration__card" v-on:click="openModalVtex">
              <img src="~@/assets/images/icones/logo-vtex.svg" alt="VTEX" />
              <p>VTEX <small>API</small></p>
            </div>

            <!-- <div class="integration__card">
              <img src="~@/assets/images/icones/logo-vtex.svg" alt="VTEX" />
              <p>VTEX <small>IO</small></p>
            </div> -->

            <!-- <div class="integration__card">
              <img src="~@/assets/images/icones/logo-tray.svg" alt="TRAY" />
              <p>Tray</p>
            </div> -->

            <!-- <div class="integration__card" v-on:click="openModalNuvemshop">
              <img src="~@/assets/images/icones/logo-nuvemshop.svg" alt="Nuvemshop" />
              <p>Nuvemshop</p>
            </div> -->
          </div>

        </div>

        <div class="list">
          <p class="list__title"><b>vDash App Key</b></p>
          <ClipboardCopy :clipboardText="keys.authenticity_token"></ClipboardCopy>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ClipboardCopy from "@/components/clipboard-copy/ClipboardCopy.vue";

export default {
  props: ["integrations", "keys"],
  components: {
    ClipboardCopy,
  },
  data() {
    return {
      list: [],
      seeactives: true,
      seeadd: true,
      buttonenabled: false,
      nuvemshop: null,
      vtexLegacy: null,
      vtexIo: null,
    };
  },
  computed: {
    ...mapState({
      profile: state => state.user.dadosCompletos,
    }),
  },
  mounted() {
    this.$emit("showModal", false);
    this.$emit("showModalNuvemshop", false);
  },
  methods: {
    handleEdit(id) {
      this.$emit("update:actives", true);
      this.$emit("update:edit", id);
      this.$emit("AddOpen", true);
      this.buttonenabled = true;
    },
    openModalVtex() {
      this.$emit("showModal", true);
    },
    openModalNuvemshop() {
      this.$emit("showModalNuvemshop", true);
    },
  },
  watch: {
    integrations(data) {
      if (!data || data.length === 0) return;

      let nuvemshop = data.filter(obj => obj.type === 'nuvemshop' && obj.nuvemshop !== null);
      this.nuvemshop = (nuvemshop.length > 0) ? nuvemshop[0] : null;

      let vtexLegacy = data.filter(obj => obj.type === 'vtexlegacy' && obj.vtex_legacy !== null);
      this.vtexLegacy = (vtexLegacy.length > 0) ? vtexLegacy[0] : null;

      let vtexIo = data.filter(obj => obj.type === 'vtexio' && obj.vtex_io !== null);
      this.vtexIo = (vtexIo.length > 0) ? vtexIo[0] : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.integration {
  max-width: 585px;
  width: 100%;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  padding: 18px;
  margin-top: 65px;
  .box {
    height: 100%;

    .title {
      // padding-bottom: 61px;
      padding: 5px 0 20px;
      &.--minor {
        padding-bottom: 20px;
      }
      p {
        font-size: 18px;
        font-family: $font__main;
        font-weight: bolder;
        color: #000000;
      }
    }
    .subtitle {
      font-size: 14px;
      line-height: 1.4;
      font-family: $font__main;
      font-weight: 500;
      color: #747474;
      margin-bottom: 20px;
    }

    .content {
      height: 65%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__cards {
        display: flex;
        width: 100%;
      }
      .list {
        cursor: pointer;
        &--cards {
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;
        }
        &__title {
          font-size: 18px;
        }
        &__icone {
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &__cod {
          color: #9f9f9f;
        }
      }
    }

    .integration__card {
      align-items: center;
      background: white;
      border: 1px solid lightgray;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      min-height: 80px;
      margin-right: 1%;
      justify-content: center;
      padding: 10px;
      width: 132px;
      text-align: center;

      &:hover {
        border-color: #1f1f1f;
      }

      img {
        height: 30px;
        max-width: 100%;
        margin: 13px;
      }
    }

    .add-button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 335px;
      width: 100%;
      height: 51px;
      border-radius: 5px;
      background-color: #41a7ff;
      cursor: pointer;

      a {
        border: none;
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        font-family: $font__main;
      }
    }

    .disabled {
      pointer-events: none;
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #965b5b;
    }
  }
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 700px) {
}
</style>
