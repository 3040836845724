<template>
  <div>
    <b-modal
      class="modalConfig"
      ref="modalConfig"
      :visible="showModal"
      id="modalConfig"
      @hidden="closeModal"
      hide-footer
      hide-header
    >
      <transition mode="out-in" name="fade-left">
        <img src="~@/assets/images/icones/logo-vtex.svg" height="40" alt="VTEX" />
      </transition>
      <div class="d-block text-left" v-if="!vtexAccount.accountSuccess">
        <div @click="closeModal" class="modalConfig__close">
          <img src="~@/assets/images/icones/close.svg" alt="Fechar" />
        </div>
        <h1 class="title">Integração VTEX <small>API</small></h1>
        <h2 class="subtitle">Para realizar essa configuração, você deve ter:</h2>
        <ul class="modalConfig__list">
          <li>
            <IconCheck icon-color="#FF2E63"></IconCheck>
            Cadastro ativo na VTEX
          </li>
          <li>
            <IconCheck icon-color="#FF2E63"></IconCheck>
            Saber o seu Account Name.
            <a href="https://help.vtex.com/pt/tutorial/o-que-e-account-name--i0mIGLcg3QyEy8OCicEoC" target="_blank">
              Saiba identificar o seu aqui
            </a>
          </li>
          <li>
            <IconCheck icon-color="#FF2E63"></IconCheck>
            Credenciais appKey e appToken.
            <a href="https://blog.vdash.com.br/como-criar-o-appkey-e-apptoken-na-vtex" target="_blank">Saiba como gerar aqui</a>
          </li>
          <li>
            <IconCheck icon-color="#26C700"></IconCheck>
            Informe as credenciais abaixo e finalize!
          </li>
        </ul>
        <ConfigForm></ConfigForm>
      </div>

      <div class="d-block text-left" v-else>
        <h1 class="title">Configuração rápida</h1>
        <div class="text-center">
          <img class="modalConfig__image" src="~@/assets/images/icones/icon-check-lg.svg" />
          <h2 class="subtitle">
            Parabéns! Sua conta
            <div class="subtitle__logo">
              vDash
            </div>
            está configurada!
          </h2>

          <p>
            A partir de agora, suas vendas serão importadas para o vDash. Para importar histórico ou dados antigos, você deve
            fazer a importação de um arquivo CSV.
            <a href="https://blog.vdash.com.br/como-importar-historico-de-pedidos-no-vdash-via-csv/" target="_blank">
              Saiba como fazer a importação aqui.
            </a>
          </p>

          <div class="button-box">
            <div v-on:click="handleSave" class="save">
              <router-link to="/settings/imports">FAZER UPLOAD DO CSV AGORA</router-link>
            </div>
          </div>

          <small></small>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConfigForm from "@/components/config-form/ConfigForm.vue";
import IconCheck from "@/components/icones/iconCheck.vue";

export default {
  props: ["showModal"],
  components: {
    IconCheck,
    ConfigForm,
  },
  data() {
    return {
      modoLayout: false,
      modalShow: false,
    };
  },
  computed: {
    ...mapState({
      vtexAccount: state => state.vtex,
      trial: state => state.plano.verifyTrial,
      showModalPlan: state => state.plano.showModal,
    }),
  },
  mounted() {
  },
  methods: {
    closeModal() {
      this.$emit("showModal", false);
      this.$refs["modalConfig"].hide();
    },
  },
};
</script>

<style lang="scss">
.modalConfig {
  &__list {
    margin-top: 20px;
    li {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 1.3;
      color: $gray;
      font-weight: 400;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    svg {
      margin-right: 10px;
    }
  }
  &__close {
    cursor: pointer;
    position: absolute;
    right: -40px;
    top: -40px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease;
    &:hover {
      transform: scale(1.2);
    }
  }
  &__image {
    margin-top: 35px;
  }
}
#modalConfig {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 691px;
      margin: 1.75rem auto;
    }
  }
  .modal-body {
    padding: 0;
  }
  .modal-content {
    border: 3px solid #ff4876;
    border-radius: 10px;
    padding: 50px 50px 30px;
  }
  .modal-header {
    border-bottom: none;
  }
  .title {
    font-size: 25px;
    font-weight: 600;
    margin-top: 25px;
  }
  .subtitle {
    font-size: 16px;
    margin-top: 15px;
    font-weight: 600;
    color: $gray;
    &__logo {
      display: inline;
      color: #0a0202;
      span {
        color: #ff4876;
      }
    }
  }
  a {
    color: $gray;
    display: contents;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #ff4876;
    }
  }
  p {
    margin-top: 20px;
    font-size: 17px;
    line-height: 1.3;
    color: $gray;
    font-weight: 400;
    a {
      display: block;
      margin-top: 20px;
    }
  }
  small {
    margin-top: 20px;
    font-size: 11px;
    display: inline-block;
  }
  .button-box {
    display: flex;
    align-items: center;
    justify-content: center;
    .save {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 335px;
      width: 100%;
      height: 51px;
      border-radius: 5px;
      background-color: #73cb53;
      margin: 7.5px;
      a {
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        font-family: $font__main;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
}
</style>
