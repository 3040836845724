<template>
  <div>
    <b-modal
      class="modalConfig"
      ref="modalConfigNuvemshop"
      :visible="showModal"
      id="modalConfig"
      @hidden="closeModal"
      hide-footer
      hide-header
    >
      <transition mode="out-in" name="fade-left">
        <img src="~@/assets/images/icones/logo-nuvemshop.svg" height="40" alt="VTEX" />
      </transition>

      <div class="d-block text-left">
        <div @click="closeModal" class="modalConfig__close">
          <img src="~@/assets/images/icones/close.svg" alt="Fechar" />
        </div>
        <h1 class="title">Integração Nuvemshop <small>APP</small></h1>
        <h2 class="subtitle">Para realizar essa configuração, você deve ter:</h2>
        <ul class="modalConfig__list">
          <li>
            <IconCheck icon-color="#FF2E63"></IconCheck>
            Cadastro ativo na Nuvemshop
          </li>
          <li>
            <IconCheck icon-color="#FF2E63"></IconCheck>
            Instalar o aplicativo em sua loja Nuvemshop
          </li>
          <li>
            <IconCheck icon-color="#FF2E63"></IconCheck>
            Fazer o login com sua conta vDash
          </li>
          <li>
            <IconCheck icon-color="#FF2E63"></IconCheck>
            Informar o vDash AppKey abaixo na hora da instalação
          </li>
        </ul>
        <div class="modalConfig__input list">
          <p class="modalConfig__title"><b>vDash App Key</b></p>
          <ClipboardCopy :clipboardText="keys.authenticity_token"></ClipboardCopy>
        </div>
        <small></small>
        <div class="button-box">
          <div class="save">
            <a href="https://www.nuvemshop.com.br/loja-aplicativos-nuvem/vdash-analytics" target="_blank">Conectar</a>
          </div>
        </div>
        <small></small>

      </div>
    </b-modal>
  </div>
</template>

<script>
import IconCheck from "@/components/icones/iconCheck.vue";
import ClipboardCopy from "@/components/clipboard-copy/ClipboardCopy.vue";

export default {
  props: ["showModal", "keys"],
  components: {
    IconCheck,
    ClipboardCopy,
  },
  data() {
    return {
      modoLayout: false,
      modalShow: false,
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("showModal", false);
      this.$refs["modalConfigNuvemshop"].hide();
    },
  },
};
</script>

<style lang="scss">
.modalConfig {
  &__list {
    margin-top: 20px;
    li {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 1.3;
      color: $gray;
      font-weight: 400;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    svg {
      margin-right: 10px;
    }
    
  }
  &__close {
    cursor: pointer;
    position: absolute;
    right: -40px;
    top: -40px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease;
    &:hover {
      transform: scale(1.2);
    }
  }
  &__image {
    margin-top: 35px;
  }
}
#modalConfig {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 691px;
      margin: 1.75rem auto;
    }
  }
  .modal-body {
    padding: 0;
  }
  .modal-content {
    border: 3px solid #ff4876;
    border-radius: 10px;
    padding: 50px 50px 30px;
  }
  .modal-header {
    border-bottom: none;
  }
  .title {
    font-size: 25px;
    font-weight: 600;
    margin-top: 25px;
  }
  .subtitle {
    font-size: 16px;
    margin-top: 15px;
    font-weight: 600;
    color: $gray;
    &__logo {
      display: inline;
      color: #0a0202;
      span {
        color: #ff4876;
      }
    }
  }
  a {
    color: $gray;
    display: contents;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #ff4876;
    }
  }
  p {
    margin-top: 20px;
    font-size: 17px;
    line-height: 1.3;
    color: $gray;
    font-weight: 400;
    a {
      display: block;
      margin-top: 20px;
    }
  }
  small {
    margin-top: 20px;
    font-size: 11px;
    display: inline-block;
  }
  .button-box {
    display: flex;
    align-items: center;
    justify-content: center;
    .save {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 335px;
      width: 100%;
      height: 51px;
      border-radius: 5px;
      background-color: #73cb53;
      margin: 7.5px;
      a {
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        font-family: $font__main;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
}
</style>
