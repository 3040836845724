<template>
  <div>
    <ModalConfig
      :showModal="showModal"
      @showModal="showModal = $event"
      ></ModalConfig>
    <ModalConfigNuvemshop
      :showModal="showModalNuvemshop"
      @showModal="showModalNuvemshop = $event"
      :keys="keys"
      ></ModalConfigNuvemshop>
    <Breadcrumbs area="Configurações" :data="location" />
    <div class="content">
      <Panel :data="location" />
      <ActiveIntegrations
        @AddOpen="see = $event"
        @update:actives="seeactives = $event"
        @update:edit="setIntegrationId"
        @showModal="showModal = $event"
        @showModalNuvemshop="showModalNuvemshop = $event"
        :integrations="integrations"
        :keys="keys"
      />
      <AddIntegration
        @closeAddIntegration="see = $event"
        v-if="see"
        :edit="idToSee"
        :integrations="integrations"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import Panel from "@/components/panel/Panel.vue";
import ActiveIntegrations from "@/components/active-integrations/Activeintegrations.vue";
import AddIntegration from "@/components/add-integration/Addintegration.vue";
import ModalConfig from "@/components/modal-config/ModalConfig.vue";
import ModalConfigNuvemshop from "@/components/modal-config/ModalConfigNuvemshop.vue";
import { listarVtexAccount } from "@/api/vtex";
import { listIntegrations } from "@/api/integrations";
import { getPerfilKeys } from "@/api/profile";

export default {
  components: {
    Breadcrumbs,
    Panel,
    ActiveIntegrations,
    AddIntegration,
    ModalConfig,
    ModalConfigNuvemshop,
  },
  data() {
    return {
      regras: [],
      panel: [],
      location: "",
      seeadd: false,
      seeactives: false,
      seeconfirm: false,
      idToSee: null,
      valueInputs: null,
      see: false,
      showModal: false,
      showModalNuvemshop: false,
    };
  },
  mounted() {
    this.fillData();
    this.loadData();
  },
  methods: {
    listarVtexAccount,
    listIntegrations,
    getPerfilKeys,
    loadData() {
      this.listarVtexAccount();
      this.listIntegrations();
      this.getPerfilKeys();
    },
    fillData() {
      this.location = "Integrações";
      this.hide = true;
    },
    setIntegrationId(id) {
      this.idToSee = id;
      // console.log('idToSee', this.idToSee)
    },
  },
  computed: {
    ...mapState({
      keys: state => state.user.keys,
      account: state => state.vtex.account,
      integrations: state => state.integrations.integrations,
    }),
  },
};
</script>

<style lang="scss" scoped>
.setup {
  height: 100%;

  .page-container {
    margin: 30px 0;
  }
}

.content {
  display: flex;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.8s;
  }
}
.box {
  margin-top: 0 !important;
}
</style>
